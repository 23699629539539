export const sentryErrorsIgnoreList = [
    'tippy is not defined',
    'j is not defined',
    "Can't find variable: tippy",
    'Livewire is not defined',
    'Unable to preload CSS for',
    'Non-Error promise rejection captured with value: Timeout',
    "null is not an object (evaluating 'Object.prototype.hasOwnProperty.call(o,\"telephone\")')",
    "Cannot read properties of undefined (reading 'L')",
    "undefined is not an object (evaluating 'a.L')",
    "Failed to read the 'localStorage' property from 'Window': Access is denied for this document.",
    'reCAPTCHA placeholder element must be an element or id',
    'reCAPTCHA has already been rendered in this element',
    'structuredClone is not defined',
    "Can't find variable: structuredClone",
];
